<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card elevation="0">
        <v-card-title class="text-h5 grey lighten-2">
          Can't finalize shipment
        </v-card-title>

        <v-card-text>
          There is no parcel assigned to this shipment. Can't finalize.
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="onClose">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialog: this.value
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
      this.reset();
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
};
</script>
